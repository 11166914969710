<template>
  <div></div>
</template>

<script>
import { Api } from "../../utils/http-common";
export default {
  name: "ActiveMail",
  created() {
    const { query } = this.$route;
    const queryToken = query.token.split("?");
    Api.userRequestServer
      .post(`/account-activation?token=${queryToken[0]}`)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          this.$router.push({
            name: this.$route.params.shopId
              ? "login shop"
              : "login shop domain",
            params: {
              shopId: query.shop_id,
            },
          });

          this.$toasted
            .success("アクティベーションに成功しました")
            .goAway(5000);
          this.$store.commit("set", ["success", false]);
          this.$store.commit("set", ["message", ""]);
        } else {
          this.$router.push({
            name: this.$route.params.shopId
              ? "login shop"
              : "login shop domain",
            params: {
              shopId: query.shop_id,
            },
          });
          this.$toasted.error("URLは利用不可となっています").goAway(5000);
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
